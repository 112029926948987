import { createAction, props } from '@ngrx/store';
import { UserStoreData } from './userstore.reducer';

export const actionPrefix = '[userstore]';

export const loadUserStore = createAction(actionPrefix + ' Load User Store');

export const loadUserStoreSuccess = createAction(actionPrefix + ' Load User Store Success', props<{ data: UserStoreData }>());

export const loadUserStoreFailure = createAction(actionPrefix + ' Load User Store Failure', props<{ error: Error }>());

export const saveUserStore = createAction(actionPrefix + ' Save User Store', props<{ data: UserStoreData }>());

export const saveUserStoreSuccess = createAction(actionPrefix + ' Save User Store Success', props<{ data: UserStoreData }>());

export const saveUserStoreFailure = createAction(actionPrefix + ' Save User Store Failure', props<{ error: Error }>());

export const initedUserStoreEffects = createAction(actionPrefix + ' Inited user Store');
